<template>
  <v-data-table
      style="border-radius: 10px!important"
      v-bind:headers="headers"
      :items="items"
      class="ma-0 pa-0 elevation-4"
    >
    <template class="ma-0 pa-0" slot="headerCell" slot-scope="props">
      <v-tooltip bottom>
        <span slot="activator" class="ma-0 pa-0 teal--text ">
          {{ props.header.text }}
        </span>
        <span>
          {{ props.header.text }}
        </span>
      </v-tooltip>
    </template>
    <template slot="items" slot-scope="props">
      <td class="ma-0 pa-2 ">{{ props.item.number }}</td>
      <td class="ma-0 pa-0 pl-2 text-xs-left">{{ props.item.name }}</td>
      <td class="ma-0 pa-0 pr-2 text-xs-right">{{ props.item.fees }}</td>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data () {
      return {
        headers: [
          {
            text: 'No',
            align: 'left',
            sortable: false,
            value: 'No'
          },
          {
            text: 'Transaction Amount (F CFA)',
            align: 'left',
            sortable: false,
            value: 'amount'
          },
          { 
              text: 'Withdrawal Fees (F CFA)',
              align: 'right',
              sortable: false,
              value: 'fees' },
        ],
        items: [
          {
            number: 1,
            value: false,
            name: '0 - 999 F CFA',
            fees: "1.5%",
          },
          {
            number: 2,
            value: false,
            name: '1000 - 5,000 F CFA',
            fees: "150 F CFA",
          },
          {
            number: 3,
            value: false,
            name: '	5,001 - 10,000 F CFA',
            fees: "225 F CFA",
          },
          {
            number: 4,
            value: false,
            name: '10,001 - 15,000 F CFA',
            fees: "350 F CFA",
          },
          {
            number: 5,
            value: false,
            name: '15,001 - 20,000 F CFA',
            fees: "450 F CFA",
          },
          {
            number: 6,
            value: false,
            name: '20,001 - 30,000 F CFA',
            fees: "600 F CFA",
          },
          {
            number: 7,
            value: false,
            name: '30,001 - 40,000 F CFA',
            fees: "700 F CFA",
          },
          {
            number: 8,
            value: false,
            name: '40,001 - 50,000 F CFA',
            fees: "800 F CFA",
          },
          {
            number: 9,
            value: false,
            name: '50,000 > ________ F CFA',
            fees: "1.5% F CFA",
          },
        ]
      }
    }
  }
</script>

<style >
  th{
    padding: 12px!important;
  }
</style>
